:root {
    --accent-color: #e51e4e;
    --background-color: #fff;
    --text-color: #000;
    --link-color: var(--accent-color);
    --subtext-color: #555;

    --header-background-color: #f5f5f5;
    --footer-background-color: #f5f5f5;

    --submit-background-color: var(--accent-color);
    --submit-text-color: #fff;
    --submit-placeholder-color: #bbb;

    --message-error-background-color: var(--accent-color);
    --message-error-text-color: #fff;

    @media screen and (prefers-color-scheme: dark) {
        --background-color: #222;
        --text-color: #eee;
        --subtext-color: #999;

        --header-background-color: #1a1a1a;
        --footer-background-color: #1a1a1a;
    }
}

html, body {
    background-color: var(--background-color);
    color: var(--text-color);
    height: 100%;
    margin: 0;
    padding: 0;
}

body {
    font-size: 1.3em;
}

html, body, textarea {
    font-family: 'PT Sans', sans-serif;

}

a {
    color: var(--link-color);
}

body {
    display: flex;
    flex-direction: column;
    gap: 10pt;
}

div.content {
    max-width: 1000pt;
    margin: 0 auto;
    padding: 0 10pt;
}

body > header {
    background-color: var(--header-background-color);
    padding: 20pt 10pt 10pt;

    .content {
        display: flex;
        gap: 10pt;
        flex-direction: row;

        @media only screen and (max-width: 700px) {
            flex-direction: column;
        }
    }

    .identity {
        flex-grow: 1;

        .title {
            text-decoration: none;
            color: var(--text-color);
            font-size: 35pt;
            font-weight: bold;
            display: block;
        }
        .subtitle {
            font-size: 20pt;
            color: var(--accent-color);
            display: block;
        }
    }
    .user {
        text-align: right;
        align-self: flex-end;

        span {
            display: inline-block;
            margin-left: 10pt;
        }
    }
}

body > footer {
    background-color: var(--footer-background-color);
    padding: 10pt;
}

#main {
    flex: 1 0 auto;
    padding-bottom: 40pt;
}

#centered {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10pt;
}

#timeline {
    h3 {
        margin-bottom: 5pt;
    }
    p {
        margin-top: 5pt;
    }

    span.deadline {
        color: var(--accent-color);
    }
}

div.front {
    header {
        h1 {
            font-size: 50pt;
            margin: 0;

            @media only screen and (max-width: 600px) {
                font-size: 40pt;
            }
        }
        .subtitle {
            display: block;
            color: var(--accent-color);
            text-align: center;
            font-size: 30pt;

            @media only screen and (max-width: 600px) {
                font-size: 25pt;
            }
        }
    }

    form {
        margin-top: 30pt;
        display: flex;
        gap: 15pt;

        input, button {
            background-color: var(--background-color);
            color: var(--text-color);
            border: 1px solid var(--accent-color);
            border-radius: 4pt;
            padding: 5pt;
            font-size: 1.1em;
        }

        & > div.field {
            flex: 1 0 auto;

            input {
                width: 100%;
            }
        }

        button {
            font-size: 15pt;
        }
    }

    p.sent {
        text-align: center;
    }
}

ul.details {
    padding: 0;
    margin-top: -15pt;

    @media only screen and (max-width: 600px) {
        font-size: 0.85em;
    }

    li {
        margin: 0;
        padding: 0;
        list-style-type: none;
        display: inline-block;

        &:not(:last-child)::after {
            content: " |";
            padding: 0 3pt;
            color: var(--subtext-color);
        }
    }
}

div.split {
    display: flex;
    gap: 20pt;

    & > div {
        flex-basis: 50%;
    }

    @media only screen and (max-width: 950px) {
        flex-direction: column;

        & > div {
            flex-basis: auto;
        }
    }
}

form.talk {
    div.field {
        margin-bottom: 20pt;
        display: flex;
        flex-direction: column;
        gap: 5pt;

        p {
            margin: 0;
            font-size: 0.8em;
            color: var(--subtext-color);
        }

        input, textarea, select {
            background-color: var(--background-color);
            color: var(--text-color);
            font-size: 1em;
            padding: 5pt;
            border: 1px solid var(--subtext-color);
            border-radius: 4pt;

            .error & {
                border-color: red;
            }
        }

        &.required label::after {
            content: "*";
            padding: 0 3pt;
            color: var(--accent-color);
        }

        label {
            display: block;
        }
    }

    div.buttons {
        margin-top: 40pt;
        text-align: right;

        &.small {
            margin-top: 0;
        }

        button, a {
            display: inline-block;
            background-color: var(--background-color);
            color: var(--text-color);
            font-size: 1em;
            text-decoration: none;
            cursor: pointer;
            border: 1px solid var(--subtext-color);
            border-radius: 4pt;
            padding: 5pt 10pt;
            margin-left: 10pt;

            &[type=submit] {
                border-color: var(--accent-color);
                background-color: var(--accent-color);
                color: var(--background-color);
            }
        }
    }
}

dl.preview {
    dd {
        margin-bottom: 20pt;

        &.textarea {
            white-space: pre-wrap;
        }
    }
}

div.submit {
    background-color: var(--submit-background-color);
    color: var(--submit-text-color);
    padding: 20pt 20pt;
    margin: 20pt 10pt;
    text-align: center;
    border-radius: 4pt;

    p {
        font-size: 1.2em;
        margin: 0 0 20pt;
    }

    input, button {
        background-color: var(--submit-background-color);
        color: var(--submit-text-color);
        border: 2px solid var(--submit-text-color);
        padding: 10pt;
        border-radius: 4pt;
        font-size: 1.1em;
    }

    input::placeholder {
        color: var(--submit-placeholder-color);
    }
}

div.session-item {
    margin-bottom: 10pt;

    ul {
        margin: 3pt 0 0;
        padding: 0;
        font-size: 0.85em;

        li {
            display: inline-block;
            margin: 0;
            padding: 0;

            &:not(:last-child)::after {
              content: " |";
              padding: 0 3pt;
              color: var(--subtext-color);
            }
        }
    }
}

div.message {
    &.error {
        background-color: var(--message-error-background-color);
        color: --message-error-text-color;
        font-weight: bold;
        padding: 5pt;
        border-radius: 4pt;
    }
}